.user-container p{
    color: white !important;
}

.user-container .center-vertical{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.user-container .main-title{
    font-size: 25px;
    padding: 1em;
    font-weight: bold;
    padding-bottom: 0 !important;
}

.user-container .cancel-padding{
    padding: 0;
}

.user-container .cancel-margin{
    margin: 0;
}

.user-container button.ui.btn-user-primary, .btn-user-primary.logout-button{
	background-color:#e18f16 !important;
	font-weight: bold !important;
	width: 200px;
    margin: 20px auto !important;
    color: #fff;
}

.btn-user-primary.logout-button{
    margin: 0 25px !important;
    width: 120px;
}

.user-container .ui.container.table-container {
	max-width: 350px !important;
	margin: 10px auto !important; 
}

.user-container .table-title{
    font-weight: bold;
}

.user-container .result{
    font-size: 50px;
    font-weight: bold;
    padding: 0.2em;
}


.user-container  .info-container{
	margin: 20px auto;
}

.user-container .info-container p{
	margin: 0 auto;
	font-size: 15px;
}

.user-container .ui.container.answers-container {
	max-width: 350px !important;
	margin-top: 35px;
}

.user-container .tower-check{
	text-align: left !important;
	display:block !important;
	margin: 15px auto;
	font-size: 18px !important;
}

.user-container .tower-check label{

	color: #fff !important;
	font-weight: bold;
	line-height: 1em !important;
	padding: 0 !important;
}

.user-container .tower-check input[type=checkbox]{
    display: none;
}

.user-container .tower-check input[type=checkbox]+label:before{
	content: '' !important;
	background-color: #fff !important;
	border-radius: 15% !important;
	display: inline-block !important;
	height: 1.2em !important;
	width: 1.2em !important;
	margin-right: 10px !important;
	text-align: center !important;
	vertical-align: text-top !important;
	position: relative;
}

.user-container .tower-check input[type=checkbox]:checked+label:before{
	content: '✓' !important;
	background-color: #e18f16 !important;
	color: #fff;
	font-size: 1.1em !important;
}

.user-container .tower-check input[type=checkbox]:checked+label:after{
	content: '' !important;
}

@media (max-width: 700px) {
	.user-container .main-title{
		font-size: 15px;
	}

	.user-container .center-vertical{
		width: 75% !important;
		transform: translate(-52%, -50%)
	}


	.user-container .ui.container.table-container {
		max-width: 350px !important;
		margin: 0px auto !important;
		margin-top: 35px;
	}
	.user-container .ui.container.table-container .row {
		padding: 0px 5px !important;
	}

	.row.login-buton{
		padding: 0 !important;
	}


}


/*-------------ADMIN STYLES-------------*/ 

.result-admin{
	border-color: black !important;
}

.result-admin p{
	color: black !important;
}

.admin-timer {
	color: black !important;
}

/*------------FOOTER STYLES--------------*/
.footer-image{
    margin: 0 0.2em !important;
    height: 0.6em !important;
    width: auto !important;
}

@media (max-width: 700px) {
	a{
		font-size: 0.8em !important;
	}

	.row{
		padding: 5px 25px !important;
	}


}
/* --------------HEADER STYLES------------- */
.header-logo{
	height: 100% !important;
}

@media (max-width: 700px) {
	.header-logo{
		padding: 10px !important;
		width: 160px;
	}

	.btn-user-primary.logout-button{
		margin: 10px !important;
	}

	.right.item{
		padding: 10px !important;
	}
}